<template>
  <div></div>
</template>

<script>
export default {
  name: 'Login',

  mounted () {
    // 如果access_token有效则回退
    if (this.$store.state.token) return this.$router.replace(this.$store.state.currentPath)
    // 尝试用refresh_token去获取access_token
    if (this.$store.state.refreshToken) return this.getAccessToken()
    const code = this.getUrlParam('code')
    const redirectUri = encodeURIComponent(this.$store.state.baseURL + '/login')
    if (code == null || code === '') {
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.$store.state.appID}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`
      return
    }
    this.login(code)
  },

  methods: {
    login (code) {
      this.api.login({ code }).then(res => {
        this.$store.commit('setToken', res.data)
        this.getUserInfo()
        this.$router.replace(this.$store.state.currentPath)
      })
    },
    // 获取Query参数
    getUrlParam (name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      const r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    // 更新access_token
    getAccessToken () {
      this.api.getAccessToken({ refreshToken: this.$store.state.refreshToken }).then(res => {
        this.$store.commit('setAccessToken', res.data.accessToken)
        this.$router.go(0)
      }).catch(() => {
        this.$store.commit('clearAll')
        this.$router.go(0)
      })
    },
    // 获取用户信息
    getUserInfo () {
      this.api.getUserInfo().then(res => {
        this.userInfo = res.data
        this.$store.commit('setUserInfo', this.userInfo)
      })
    }
  }
}
</script>
